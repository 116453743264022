import React from 'react'
import dp from './assets/avatar7.jpg'
import resume from './assets/Rudrakshi_Resume.pdf'

export default function About() {
  return (
    <div id='about' className='flex'>

      <div className="title">About Me</div>

    <div className='mainab flex'>
        <div className="imgmy" > <img id='dp' src={dp} alt='dp'></img> </div>
           <div className="infomy">
            <h1>I'm Rudrakshi</h1>
            <h2 className='h2'>ReactJs Devloper </h2>
            <h3 className='h3'>I'm ReactJs Devloper based in Pune, India. I'm Bachelor of Computer 
              Engineering from SPPU.I am Passionate about building
                web applications, I like to imporve myself, my skills. My goal is to create web applications that are 
                user-friendly, and that are easy to use.
            </h3>
            
            <h3 id='key'>
              <span className='blue'>Age:</span>21  <br/>
              <span className='blue'>Email:</span>padole.ruda123@gmail.com
              </h3>


            <h3><span className='blue '>Phone no:</span>+91-92847 13700  <br/>           
            <span className='blue'>Place:</span>Pune</h3>
             <a href={resume} download>
              <button className='resume'>RESUME ⬇ </button>
             </a>
             {/* <a href={resume}>
             <button className='resume'>View</button>
             </a> */}

             </div>
    </div>
</div>
 

   
  )
}
