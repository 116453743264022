import React from 'react';
import ReactDOM from 'react-dom/client';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import Home from './Home';
import './index.css';
import Navbar from './Navbar';
import Projects from './Projects';
import Skills from './Skills';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Navbar/>
 <Home/>
 <About/>
 <Projects/>
 <Skills/>
 {/* <Contact/> */}
 <Footer/>
  </React.StrictMode>
);

