import React from 'react'
import htmicon from './assets/htmicon.png'
import css from './assets/css.png'
import javascript from './assets/javascript.png'
import react from './assets/react.png'
import fb from './assets/fb.png'
import mdb from './assets/mdb2.png'
import bst from './assets/bst.png'
import redux from './assets/rdux.png'
export default function Skills() {
  return (
    <>
   <section id='skills'>
    <div className='container'></div>

    <h1 className='title'>My <u>Skills</u></h1>
    <div className='flex flex-1 f-w'>
        <div className=' card flex-1'>
            <img src={htmicon} height={100} width={100}/>
            <h3 class="head">Html</h3>
                <p>Html is very important part of web devlopement.
                    I have a good knowledge of Html.
                </p>
            </div>
            <div class="card flex-1">
                <img src={css} height="100" width="100"/>
                <h3>CSS</h3>
                <p>CSS is very important part of web devlopement.
                    I have a good knowledge of CSS.</p>   
            </div>
            <div class="card flex-1">
                <img src={javascript} height="100" width="100"/>
                <h3>JS</h3>
                <p>JS is very important part of web devlopement.
                    I have a good knowledge of JS.</p>   
            </div>
            <div class="card flex-1">
                <img src={react} height="100" width="100"/>
                <h3>React</h3>
                <p>React is very important part of web devlopement.
                    I have a good knowledge of React.</p>   
            </div>
            <div class="card flex-1">
                <img src={fb} height="100" width="100"/>
                <h3>Firebase</h3>
                <p>I have a good knowledge of Firebase </p>   
            </div>
            <div class="card flex-1">
                <img src={mdb} height="100" width="100"/>
                <h3>MongoDbB</h3>
                <p>I have a good Knwoledge about Mongodb </p>   
            </div>
            <div class="card flex-1">
                <img src={bst} height="100px" width="100px"/>
                <h3>Bootstrap</h3>
                <p>I have a good Knwoledge about Bootstrap</p>   
            </div>
            <div class="card flex-1">
                <img src={redux} height="100" width="100"/>
                <h3>Redux</h3>
                <p>I have a good Knwoledge about Redux</p>   
            </div>
    </div>

    
   </section>
    </>
  )
}
