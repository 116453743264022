import React from 'react'
import a3 from './assets/a3.png'

export default function Home() {
  return (
   <>
   <div className='flex' id='home' >
    <div className="info flex" >
      <h3>Welcome To My Website!</h3>
      <h1>Hi There,</h1>
      <h1>I'm  Rudrakshi Padole</h1>
      <h2>I am Fullstack Web Devloper</h2>
      <a className='btnh flex' href='#footer'>Hire Me</a>
    </div>
    <div className="imgdiv flex">
      <img src={a3} alt="avtar"></img>
    </div>
   </div>
   </>
  )
}
