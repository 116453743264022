import React from 'react'

export default function Navbar() {
  return (
  <>
  <div className="navbar  flex">
<h1  > <a href='#home' className="logo"> Rudrakshi Padole </a> </h1>
      <ul  className="navlinkbar flex">

          <a className="navlink" href='#about'>About</a>
          <a className="navlink" href='#projects' >Projects</a>
          <a className="navlink" href='#skills'>Skills</a>
          <a className="navlink" href='#footer'>Contact Me</a>

     </ul>
  </div>
  
  
  
  </>
  )
}
