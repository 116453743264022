import React from 'react'
import tic from './assets/tic1.png'
import todoo from './assets/todo1.png'
import music from './assets/music1.png'
import nom from './assets/guess.png'

export default function  Projects() {
  return (
    <>
    <div id="projects">
       <div className='title '>Some of my recent <u>Projects</u></div>
      <div className='flex cardMain'>
     
    
      <div className='card2 flex'>
      <div className='imgdiv2 flex'><img src={nom}  width="90%"></img></div>
        <h2 className='a-i'>Guessing Game</h2>
       <button className='btnProject'>
       <a className='a1' href='http://rudrakshipadole.in/guessing-game'>Play Game</a></button> 
      </div>

      <div className='card2 flex'>
      <div className='imgdiv2 flex'> <img src={music} width="90%"></img></div>
        <h2 className='a-i'>Rudra's Playlist</h2>
        <button className='btnProject'>
       <a className='a1' href='http://rudrakshipadole.in/music-player'>Play Playlist</a></button> 
      </div>

      <div className='card2 flex'>
      <div className='imgdiv2 flex'> <img src={tic} width="90%"></img></div>
        <h2 className='a-i'>Tic-Tac-Toe</h2>
        <button className='btnProject'>
       <a className='a1' href='http://rudrakshipadole.in/tic-tac'>Play Game</a></button>
      </div>

      <div className='card2 flex'>
      <div className='imgdiv2 flex'><img src={todoo} width="90%"></img></div>
        <h2 className='a-i'>ToDo List📝</h2>
        <button className='btnProject'>
       <a className='a1' href='http://rudrakshipadole.in/todo'>Make List</a></button>
      </div>



      </div>
      
    </div>
   

   </>
  )
}
