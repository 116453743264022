import React from 'react'



export default function() {
  return (
    <>
    <div id='footer'>
    <div>
    <h1>Thank You For Visiting us! Let's Stay Connected </h1>
     <div className='flex'>
     <a className="icon" id="i1" href="https://www.linkedin.com/in/rudrakshi-padole-32266121b/"><i className="fab fa-linkedin"></i></a>
      <a className="icon" id="i2" href="https://wa.me/9284713700"><i className="fab fa-whatsapp"></i></a>
      <a className="icon" id="i3" href="https://github.com/Rudrakshipadole"><i className="fab fa-github"></i></a>
      <a className="icon" id="i4" href="https://www.instagram.com/___rudrakshi___/"><i className="fab fa-instagram"></i></a>
      {/* <a className="icon" id="i5" href="https://twitter.com/raykarabhi43"><i className="fab fa-twitter"></i></a> */}
      {/* <a className="icon"id="i6" href="https://www.facebook.com/abhisheak.raikar"><i className="fab fa-facebook-f"></i></a> */}


     </div>
    
    </div>
      
    </div>
    
    </>
  )
}
